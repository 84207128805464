import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { ComponentPageNav } from '~/src/components/component-page-nav';
import StatusMenu from '~/src/components/status-menu';
import { AccessibilityLabel, StatusLabel } from '@primer/gatsby-theme-doctocat';
import { Label } from '@primer/react';
import { graphql } from "gatsby";
export const query = graphql`
  query {
    primerReactVersion {
      version
    }
    allReactComponent(filter: {componentId: {eq: "underline_nav"}}) {
      nodes {
        status
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Box = makeShortcode("Box");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Box style={{
      marginBottom: "24px"
    }} mdxType="Box">
  <ComponentPageNav basePath="/components/underline-nav" includeReact={true} includeRails={true} current="react" mdxType="ComponentPageNav" />
    </Box>
    <Box style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "25px"
    }} mdxType="Box">
  <Box style={{
        display: "flex",
        gap: "5px"
      }} mdxType="Box">
    <Label size="large" mdxType="Label">@primer/react@v{props.data.primerReactVersion.version}</Label>
    <StatusLabel status="Deprecated" mdxType="StatusLabel" />
    <AccessibilityLabel a11yReviewed={false} short={false} mdxType="AccessibilityLabel" />
  </Box>
  <Box mdxType="Box">
    <StatusMenu currentStatus="deprecated" statuses={[...props.data.allReactComponent.nodes.map(reactComponent => reactComponent.status), "deprecated"]} parentPath="/components/underline-nav/react" mdxType="StatusMenu" />
  </Box>
    </Box>
    <h2>{`Deprecation`}</h2>
    <p>{`Use `}<a parentName="p" {...{
        "href": "/components/underline-nav/react/latest"
      }}>{`the new version of UnderlineNav`}</a>{` with design and accessibility updates.`}</p>
    <p><strong parentName="p">{`Attention:`}</strong>{` Make sure to properly label your `}<inlineCode parentName="p">{`UnderlineNav`}</inlineCode>{` with an `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to provide context about the type of navigation contained in `}<inlineCode parentName="p">{`UnderlineNav`}</inlineCode>{`.`}</p>
    <h2>{`Examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<UnderlineNav aria-label="Main">
  <UnderlineNav.Link href="#home" selected>
    Home
  </UnderlineNav.Link>
  <UnderlineNav.Link href="#documentation">Documentation</UnderlineNav.Link>
  <UnderlineNav.Link href="#support">Support</UnderlineNav.Link>
</UnderlineNav>
`}</code></pre>
    <h3>{`UnderlineNav`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Actions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React.ReactNode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Place another element, such as a button, to the opposite side of the navigation items.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`'right'`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use `}<inlineCode parentName="td">{`right`}</inlineCode>{` to have navigation items aligned right.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`full`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used to make navigation fill the width of the container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aria-label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used to set the `}<inlineCode parentName="td">{`aria-label`}</inlineCode>{` on the top level `}<inlineCode parentName="td">{`<nav>`}</inlineCode>{` element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sx`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/styled-system__css/index.d.ts#L407"
            }}>{`SystemStyleObject`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3>{`UnderlineNav.Link`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react/index.d.ts#L73"
            }}>{`React.ElementType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`'a'`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The underlying element to render — either a HTML element name or a React component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`selected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sx`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/styled-system__css/index.d.ts#L407"
            }}>{`SystemStyleObject`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      